import React, { useEffect, useState } from 'react';
import './App.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

import om from './assets/om-swastyastu.png'
import orang1 from './assets/solo_1.jpg'
import orang2 from './assets/solo_2.jpg'
import orang3 from './assets/solo_3.jpg'
import orang4 from './assets/solo_4.jpg'
import lengkap from './assets/dandy2.jpg'
import flower from'./assets/frame.png';
import flower2 from'./assets/flower-2.png';
import { ReactComponent as CalendarIcon } from './assets/calendar-days-regular.svg';
import { ReactComponent as ClockIcon } from './assets/clock-regular.svg';

import Countdown from './components/Countdown';
import Modal from './components/Modal/Modal';
import Galery from './components/Gallery/GalleryDisplay';

const Invitation = () => {
  const [openModal, setOpenModal] = useState(true);
  const [openContent, setOpenContent] = useState(false);

  const [myName, setMyName] = useState('Guest');

  useEffect(() => {
    // Extract the 'name' parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const nameParam = urlParams.get('name');

    // Set the name from the parameter, or use 'Guest' as a default
    setMyName(nameParam || 'Guest');
  }, []); // Set your target date (e.g., New Year's Eve)


    // Set your target date (e.g., New Year's Eve)
    const targetDate = new Date('2024-05-02T23:59:59').getTime();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const exactDate = new Date(targetDate).toLocaleDateString('id-ID', options);

    const timeRemaining = Countdown({ targetDate });
  
    const { days, hours, minutes, seconds } = timeRemaining;
  
    const formattedDate = new Date(targetDate).toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const formattedDateHari = new Date(targetDate).toLocaleDateString(undefined, {
      weekday:'long'
    });
    
    const formattedDateBulan = new Date(targetDate).toLocaleDateString(undefined, {
      month:'long'
    });
    
    const formattedDateTahun = new Date(targetDate).toLocaleDateString(undefined, {
      year:'numeric'
    });
    const formattedDateHariNumberi = new Date(targetDate).toLocaleDateString(undefined, {
      day:'numeric'
    });
  
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const formattedTimeDay = `${days}`;
    const formattedTimeHour = `${hours}`;
    const formattedTimeMinute = `${minutes}`;
    const formattedTimeSecond = `${seconds}`;

    useEffect(() => {
      AOS.init({
        duration: 1500, // Set your default animation duration
        delay: 100,    // Set your desired delay before animations start
        once:true,
      });
    }, []);

  
    


    
  return (

    <div className='' >
      {openModal && <Modal closeModal={setOpenModal} undanganName={myName}/>}
       {!openModal && (
             <div className="column">
             <div className="jumbotron">
               <div className='jumbotron-text'>
                 
                 <p data-aos="fade-up">Upakara Mepandes</p>
                 <h1 data-aos="fade-up">Bagus Bayu & Tara</h1>
                 <p>{exactDate}</p>
               </div>
               
               {/* <div className="bottom">
         
                 <div className="black">
                   
                 </div>
                 <div className="black3 center flower-2">
     
                   <img src={flower2} alt="" />
                   
                 </div>
                 <div className="black2">
                   
                 </div>
     
     
               </div> */}
             
                 {/* style 2 */}
                 <div className="wave">
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                     <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                     <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                     <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                   </svg>
     
                 </div>
                 {/* style2 end */}
     
     
             </div>
             
     
             <div className="bg">
              <div className="bg-layer">
               <div className="container">
     
                   <div className="content center flex column gap-1rem">
                 
     
                     <div className="om" data-aos="fade-up">
                       <img src={om} alt="" />
                       
                     </div>
                     <p data-aos="fade-up">
                     Atas Asung Kertha Wara Nugraha Ida Sang Hyang Widhi Wasa/ Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/ Ibu/ Saudara/ i pada Upacara Manusa Yadnya Mepandes putra dan putri kami.
     
                     </p>
                     <p data-aos="fade-up">Welcome, {myName}!</p>
                   </div>
     
                   
                   
                   <div className="flex align-center content gap">
     
                     <div className="card content" data-aos="fade-up">
     
                       <div className="foto-orang">
                         <img src={orang1} alt="" />
                       </div>
                       <div className="card-desc">
                         <p className='tangerine'>I Putu Bagus Bayu Ratdana, S.Kom</p>
                         <p>Putra Pertama Dari Pasangan</p>
                         <p>I Nyoman Sutika</p>
                         <p>&</p>
                         <p>Ni Ketut Suraini</p>
                         <p>Jln Dangin Labak ,Desa Beraban Tabanan( Gang Rama)</p>
                       </div>
                       <div className="card-frame">
                         <img src={flower} className='flower' />
                       </div>
     
                     </div>
                     <div className="card content" data-aos="fade-up">
     
                       <div className="foto-orang">
                         <img src={orang2} alt="" />
                       </div>
                       <div className="card-desc">
                         <p className='tangerine'>I Putu Bagus Bayu Ratdana, S.Kom</p>
                         <p>Putra Pertama Dari Pasangan</p>
                         <p>I Nyoman Sutika</p>
                         <p>&</p>
                         <p>Ni Ketut Suraini</p>
                         <p>Jln Dangin Labak ,Desa Beraban Tabanan( Gang Rama)</p>
                       </div>
                       <div className="card-frame">
                         <img src={flower} className='flower' />
                       </div>
                     </div>
     
                   </div>
                   <div className="flex align-center content gap">
     
                     <div className="card content" data-aos="fade-up">
     
                       <div className="foto-orang">
                         <img src={orang3} alt="" />
                       </div>
                       <div className="card-desc">
                         <p className='tangerine'>I Putu Ipsum, S.Kom</p>
                         <p>Putra Pertama Dari Pasangan</p>
                         <p>I Nyoman Lorem</p>
                         <p>&</p>
                         <p>Ni Ketut Dolor</p>
                         <p>Jln Lorem ipsum dolor</p>
                       </div>
                       <div className="card-frame-2">
                         <img src={flower2} className='flower' />
                       </div>
     
                     </div>
                     <div className="card content" data-aos="fade-up">
     
                       <div className="foto-orang">
                         <img src={orang4} alt="" />
                       </div>
                       <div className="card-desc">
                         <p className='tangerine'>I Putu Bagus Bayu Ratdana, S.Kom</p>
                         <p>Putra Pertama Dari Pasangan</p>
                         <p>I Nyoman Sutika</p>
                         <p>&</p>
                         <p>Ni Ketut Suraini</p>
                         <p>Jln Dangin Labak ,Desa Beraban Tabanan( Gang Rama)</p>
                       </div>
                       <div className="card-frame-2">
                         <img src={flower2} className='flower' />
                       </div>
                     </div>
     
                   </div>
                   </div>

              </div>
                 </div>
                   <div className="white bg-gold center pad-3 italic">
                     <p data-aos="fade-up">
                     Ihaiva stam mā vi yaustam,
                     Visvām āyur vyasnutam.
                     Krindantau putrair naptrbhih,
                     Modamānau sve grhe.
                     
                     </p>
                     <p data-aos="fade-up">
                     Wahai pasangan suami-isteri, semoga kalian tetap bersatu dan tidak pernah terpisahkan. Semoga kalian mencapai hidup penuh kebahagiaan, tinggal di rumah yang penuh kegembiraan bersama seluruh keturunanmu.
                     </p>
                     <h4 data-aos="fade-up">~ Rgveda : X.85.42 ~</h4>
                   </div>
                   <div className="bg">
                    <div className="bg-layer">
                      <div className="pad-3">
                        <div className="flex align-center">
        
                          <div className="card-2 flex-1 flex">
                            <div className="flex column gap-1rem">
                              {/* <div className="flower-2">
                                <img src={flower2} className='flower2' />
                              </div> */}
                              <h1 className='gold'>Acara Mepandes</h1>
                              <p>Merupakan suatu kehormatan dan kebahagiaan bagi kami, apabila Bapak/Ibu/Saudara/i berkenan hadir untuk memberikan doa restu kepada kami pada :</p>
                              <div className="card-2-content">
                                <div className="border-gold flex column flex-1 align-center">
                                <CalendarIcon className="svg-gold icon" />
                                  <p>{formattedDateHari}</p>
                                  <p>{formattedDateHariNumberi} {formattedDateBulan} {formattedDateTahun} </p>
                                </div>
                                <div className="border-gold flex column flex-1 align-center">
                                  <ClockIcon className='svg-gold icon' />
                                  <p>14:00 WITA</p>
                                  <p>s/d selesai</p>
                                </div>
                              </div>
                            </div>
        
                            
                          </div>
        
                        </div>

                      </div>

                    </div>
     
     
                         {/* <p>{myName}</p>
                         <h1>{formattedDate}</h1>
                         <h1>{formattedTime}</h1>
                         <p>{days} days, {formattedTime} remaining</p> */}

     

                         {/* <button 
                           className='openModalBtn' 
                           onClick={() => 
                           {setOpenModal(true)}}>
                             Open
                           </button> */}
       
     
                   
                   </div>
                   <div className="center flex column white bg-gold">
                      <div className="countdown">
                        <h1 data-aos="fade-up">Menuju Acara</h1>
                      </div>
                      <div className="countdown">
                        <div className="countdown-time column center" data-aos="fade-right">
                          <p>{formattedTimeDay}</p>
                          <p>hari</p>
                        </div>
                        <div className="countdown-time column center" data-aos="fade-right">
                          <p>{formattedTimeHour}</p>
                          <p>jam</p>

                        </div>
                        <div className="countdown-time column center" data-aos="fade-right">
                          <p>{formattedTimeMinute}</p>
                          <p>menit</p>
                
                        </div>
                        <div className="countdown-time column center" data-aos="fade-right">
                          <p>{formattedTimeSecond}</p>
                          <p>detik</p>
                        </div>
                        
                      </div>
                    </div>

                    <div className="bg-map">
                      <div className="bg-layer">
                        <div className=" container center flex column gap">
                        <img src={flower} className='map-frame-top' />
                          <div className="title gold">
                            <h1>Lokasi Acara</h1>
                          </div>


                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.1471544056726!2d115.23409269999999!3d-8.677552900000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd241e38968bf3d%3A0xe82a1198a1bff5d1!2sKOPTE%20Panjer!5e0!3m2!1sen!2sid!4v1714641019110!5m2!1sen!2sid"  loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='google-map'></iframe>

                         
                          
                            <a href="https://maps.app.goo.gl/3MPJqKNgGqeK37BY6" className='button-map'> <p>Buka di Google Maps</p> </a>
                        
                          <img src={flower} className='map-frame-bot' />
                        </div>



                      </div>
                    </div>

                    <Galery />
                    
                    <div className="white bg-gold center pad-3 italic">
                    <div className="title">
                        <h1>Pesan</h1>
                    </div>
                    
                      
                     <p>Merupakan Suatu Kebahagiaan dan Kehormatan bagi Kami,</p>
                     <p>Apabila Bapak/Ibu/Saudara/i, Berkenan Hadir di Acara kami Terima Kasih Mepandes/Metatah</p>

                   </div>
                   <div className="white bg-black center pad-3">
                      
                     <p>© Copyright 2023 Catha. All Rights Reserved.</p>

                   </div>

               </div>
      )}

      </div>

  );
};

export default Invitation;
